<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"> Nouveau patrimoine</span>

          <div>
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="createItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <div class="row" id="add-page">
          <!-- First Col -->
          <div class="col-md-6 col-12">
            <b-form-group
              label-cols="4"
              label="Libellé :"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="libelle"
                placeholder="Libellé"
                :class="{
                  'is-invalid': $v.libelle.$error && $v.libelle.$dirty,
                }"
                type="text"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.libelle.$dirty"
              >
                {{ !$v.libelle.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Responsable :"
              label-class="font-weight-bold"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid': $v.selectedCol.$error && $v.selectedCol.$dirty,
                }"
              >
                <v-select
                  label="value"
                  v-model="selectedCol"
                  :options="responsables"
                  :reduce="(responsable) => responsable.id"
                  placeholder="Responsable"
                  :class="{
                    'is-invalid':
                      $v.selectedCol.$error && $v.selectedCol.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedCol.$dirty"
              >
                {{ !$v.selectedCol.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Description :"
              label-class="font-weight-bold"
            >
              <b-form-textarea
                placeholder="Description..."
                v-model="description"
              ></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-md-6 col-12">
            <b-form-group
              label-cols="4"
              label="Entité :"
              label-class="font-weight-bold"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.selectedEntite.$error && $v.selectedEntite.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  :options="entityList"
                  label="text"
                  placeholder="Entité"
                  v-model="selectedEntite"
                  :reduce="(entite) => entite.value"
                  @input="selectedOrganisation = null"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedEntite.$dirty"
              >
                {{ !$v.selectedEntite.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Organisation :"
              label-class="font-weight-bold"
            >
              <div
                class="font-weight-normal p-0 form-control"
                style="padding: 0 !important; height: fit-content"
                :class="{
                  'is-invalid':
                    $v.selectedOrganisation.$error &&
                    $v.selectedOrganisation.$dirty,
                }"
              >
                <v-select
                  class="bg-transparent"
                  label="text"
                  placeholder="Organisation"
                  v-model="selectedOrganisation"
                  :options="organisations"
                  :reduce="(organisation) => organisation.value"
                  :class="{
                    'is-invalid':
                      $v.selectedOrganisation.$error &&
                      $v.selectedOrganisation.$dirty,
                  }"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </div>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.selectedOrganisation.$dirty"
              >
                {{
                  !$v.selectedOrganisation.required ? "Champ obligatoire" : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="row my-5">
        <div class="col-12">
          <b-tabs
            :value="$store.state.tabs.activeTab"
            @changed="activeTab = $store.state.tabs.activeTab"
            @activate-tab="
              (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab title="Référents">
              <patrimony-referent :items="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Portefeuille processus">
              <PatrimonyProcess :items="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Portefeuille applications">
              <patrimony-applications :items="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Portefeuille composant technique">
              <ComposantTable :items="[]" :disabled="true" />
            </b-tab>
            <b-tab title="Portefeuille cadres d’entreprise">
              <RefCardsTable :items="[]" :disabled="true"></RefCardsTable>
            </b-tab>
            <b-tab title="FdR du patrimoine">
              <patrimony-fdr :disabled="true" />
            </b-tab>

            <b-tab title="Transformation du patrimoine">
              <patrimony-transformation-map :disabled="true" />
            </b-tab>

            <b-tab title="KPI" v-if="false">
              <patrimony-kpi :disabled="true" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import PatrimonyApplications from "./components/PatrimonyApplications.vue";
import PatrimonyTransformationMap from "./components/PatrimonyTransformationMap.vue";
import PatrimonyProcess from "./components/PatrimonyProcess.vue";
import PatrimonyReferent from "./components/PatrimonyReferent.vue";
import PatrimonyFdr from "./components/PatrimonyFdr.vue";
import PatrimonyKpi from "./components/PatrimonyKpi.vue";
import RefCardsTable from "./components/RefCardsTable";
import { required } from "vuelidate/lib/validators";
import ComposantTable from "./components/ComposantTable";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  components: {
    ModalActions,
    RefCardsTable,
    PatrimonyApplications,
    PatrimonyTransformationMap,
    PatrimonyProcess,
    PatrimonyReferent,
    PatrimonyFdr,
    PatrimonyKpi,
    ComposantTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    selectedCol: null,
    libelle: "",
    description: "",
    selectedEntite: null,
    selectedOrganisation: null,
  }),

  validations: {
    selectedCol: {
      // initiative
      required,
    },
    libelle: {
      // initiative
      required,
    },
    selectedEntite: {
      required,
    },
    selectedOrganisation: {
      required,
    },
  },
  methods: {
    createItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitingForm = true;
        var item = {
          name: this.libelle,
          description: this.description,
          responsable: `/api/users/${this.selectedCol}`,
          organisation: this.selectedOrganisation,
        };
        this.$store.dispatch("patrimoine/createPatrimoines", item).then(() => {
          this.submitingForm = false;
        });
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");

    this.id = this.$route.params.id;
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("patrimoine", ["PATRIMOINE", "PATRIMOINES"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters(["AuthenticatedUser", "isAdmin", "isSuper"]),

    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      })).filter(
        (respo) =>
          !this.PATRIMOINES.find(
            (patrimoine) => patrimoine.responsable.id === respo.id
          )
      );
      return data;
    },
    entityList() {
      return this.ENTITIES.filter(
        ({ organisations }) => organisations && organisations.length
      ).map((entite) => ({
        value: entite["@id"],
        text: entite.libelle,
      }));
    },
    organisations() {
      // return this.selectedEntite
      //   ? this.ENTITIES.find(
      //       (entite) => entite["@id"] == this.selectedEntite
      //     ).organisations.map((organisation) => ({
      //       value: organisation["@id"],
      //       text: organisation.libelle,
      //     }))
      //   : [];
      return this.selectedEntite
        ? this.isAdmin || this.isSuper
          ? this.ENTITIES.find(
              (entite) => entite["@id"] == this.selectedEntite
            ).organisations.map((organisation) => ({
              value: organisation["@id"],
              text: organisation.libelle,
            }))
          : this.AuthenticatedUser &&
            this.AuthenticatedUser.organisationRatachement
          ? [this.AuthenticatedUser.organisationRatachement].map(
              (organisation) => ({
                value: organisation["@id"],
                text: organisation.libelle,
              })
            )
          : []
        : [];
    },
  },
  watch: {
    PATRIMOINE() {
      Swal.fire({
        title: "Le patrimoine est bien créé !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-patrimony",
            params: {
              slug: this.PATRIMOINE.slug || "abcd",
              id: this.PATRIMOINE.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style>
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
  height: 100% !important;
  background: white;
}
.vs__search {
  color: #cfcfcf;
}
</style>
